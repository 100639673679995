const colors = {
    background: '#ABC4AB',
    buttonPrimary: '#6D4C3D',
    greenAccent: '#727D71',
    tan: '#DCC9B6',
    olive: '#A39171'
  };
  
  const commonStyles = {
  
  };
  
  export { colors, commonStyles };
  